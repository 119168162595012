<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <div class="tabs">
            <el-tabs v-model="vipType">
<!--                <el-tab-pane label="个人" name="person"></el-tab-pane>-->
                <el-tab-pane label="企业" name="company"></el-tab-pane>
            </el-tabs>
        </div>

        <el-button style="margin-bottom: 10px" type="primary" size="mini"
                   plain icon="el-icon-plus" @click="handleAdd"
        >新增
        </el-button>

        <product-items
            v-show="vipType === 'person'"
            :product-list="productPersonList"
            @modify-success="modifySuccess"
            @handle-edit="handleEdit"
        >
        </product-items>
        <product-items
            v-show="vipType === 'company'"
            :product-list="productCompanyList"
            @modify-success="modifySuccess"
            @handle-edit="handleEdit"
        ></product-items>

        <add-or-edit
            v-if="dialogVisible"
            :dialog-visible="dialogVisible"
            @close-form="handleClose"
            @modify-success="modifySuccess"
            :vip-product="formData"
        >
        </add-or-edit>
    </div>
</template>

<script>
import {listAPI} from './api'
import productItems from "@/views/vipProduct/component/productItems.vue";
import addOrEdit from "@/views/vipProduct/component/addOrEdit.vue";

export default {
    name: 'list',
    components: {
        productItems, addOrEdit
    },
    data() {
        return {
            dialogVisible: false,
            vipType: 'company',
            formData: {
                id: 0,
                title: '',
                type: 10,
                price: 0,
                year: 1,
                discount: 0.0
            },
            total: 0,
            searchData: {
                page: 1,
                pagesize: 10,
            },
            productCompanyList: [],
            productPersonList: [],
        }
    },

    provide() {
        return {
            context: this
        }
    },
    watch: {
        'vipType': {
            handler(val) {
                this.formData.type = (val === 'person' ? 10 : 30)
            }
        }
    },
    mounted() {
        this.getList()
    },

    methods: {
        async getList() {
            const res = await listAPI(this.searchData)
            const list = res.data || []

            this.productCompanyList = list.filter(item => item.type === 30)
            this.productPersonList = list.filter(item => item.type === 10)
        },

        modifySuccess() {
            this.dialogVisible = false
            this.formData = {
                id: 0,
                title: '',
                type: 10,
                price: 0,
                year: 1,
                discount: 0.0
            }
            this.getList()
        },

        handleAdd() {
            this.dialogVisible = true
        },

        handleClose() {
            this.dialogVisible = false
        },

        handleEdit(row) {
            console.log(row, "@@@")
            this.dialogVisible = true
            this.formData = row
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;
    min-width: 800px;

    .search-header /deep/ {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .arrow {
            text-align: right;

            .open {
                color: #409eff;
                font-size: 16px;
                margin: 0 10px;
                cursor: pointer;
            }

            .updown {
                color: #409eff;
                font-size: 16px;
                transition: all 0.5s;
            }

            .go {
                transform: rotate(-180deg);
            }
        }

        .el-form-item {
            width: 20%;
            min-width: 300px;
            margin-bottom: 0;
            margin-right: 0;
        }
    }

    .el-divider {
        margin: 20px 0;
    }

    .btn-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
</style>
